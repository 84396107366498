// Footer.js
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Logo from "../principal/logo-negro.png";

const Footer = () => {
  return (
    <Container>
      <Row className="d-flex justify-content-center align-items-center text-center">
        <Col xs={12} className="d-flex justify-content-center align-items-center flex-column">
          <img src={Logo} className="img-fluid" alt="Logo" style={{ maxWidth: '200px' }} />
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
