import React from "react";
import Bodegon from "./img/bodegones.jpg";
import Logo from './logo-negro.png'
import "./principal.css"; // Archivo CSS para estilos adicionales si es necesario
import Layout from '../layouts/layout.js';


const Principal = () => {
  return (
  <Layout>
    <div className="d-flex justify-content-center align-items-center vh-100 flex-column">
      <img src={Bodegon} className="img-fluid custom-img-height pb-2" alt="Bodegon" />
      <img src={Logo} className=" col-xl-2" alt="Bodegon" />
    </div>
    </Layout>

  );
};

export default Principal;
