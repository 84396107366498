// About.js
import React from 'react';
import { Row, Col, Container} from 'react-bootstrap'; // Importa las clases de Bootstrap
import Layout from '../layouts/layout.js';

const Terms = () => {


  return (
    <Layout>
      <Container>
        <Row className="d-flex justify-content-center align-items-center text-left min-vh-100">
        <Col md={10}> {/* Ajusta la columna según sea necesario */}
            <h2>Entrega y devoluciones:</h2>
            <p>
              El envío no está considerado en el precio de las piezas, este depende de la zona geográfica de despacho
              (para envíos internacionales comunícate a info@pieceofarte.com).
            </p>
            <p>
              Por compras superiores a $100.000 el envío es gratuito dentro del área de Santiago y sobre $170.000 para
              regiones de Chile. También puedes optar a retirar de manera gratuita en nuestro estudio ubicado en Providencia.
            </p>
            <p>
              El despacho se realizará a través de una empresa externa, por lo cual nuestra firma no se hace responsable
              por posibles daños al momento de su recepción.
            </p>
            <p>
              Piece of arte acepta solo devoluciones por falla dentro de un periodo de 30 días hábiles. El ingreso de la
              pieza para su reparación lo asume la firma, el retorno del producto debe ser pagado por el cliente.
            </p>

            <h2>Garantía de reparación:</h2>
            <p>
              Válida hasta 30 días desde la fecha de compra. Nuestra firma solo se hace cargo de fallas relacionadas con
              broches, uniones, herrajes, etc. El tiempo de reparación es aprox de 2 a 4 semanas (se debe entregar la
              totalidad de la pieza a reparar).
            </p>

            <h2>Esta garantía no cubre:</h2>
            <ul>
              <li>Piezas metálicas o de cerámica quebradas.</li>
              <li>Cortes de cadena.</li>
              <li>Piezas aplastadas o deformadas.</li>
              <li>Piedras o Perlas quebradas.</li>
              <li>Joyas rayadas, grabadas o intervenidas por terceros.</li>
            </ul>

            <h2>Cuidado de las piezas:</h2>
            <ul>
              <li>Las piezas deben ser limpiadas con paño incluido en la compra.</li>
              <li>No se recomienda mojar ni exponerlas a cambios extremos de temperatura.</li>
              <li>Evitar la humedad, cremas o perfumes de manera directa.</li>
              <li>El color del metal puede variar dependiendo del PH de cada persona.</li>
              <li>No golpear o dejar caer (al ser cerámica son delicadas).</li>
              <li>Comprender que la pieza está esculpida y creada a mano en materiales nobles y delicados.</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Terms;
