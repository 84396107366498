// Layout.js
import React from 'react';
import Sidebar from '../sidebar/sidebar';
import { Container, Row, Col } from 'react-bootstrap'; // Importa las clases de Bootstrap

const Layout = ({ children }) => {
  return (
    <Container fluid>
      <Row>
        <Col md={2} className="p-0">
          <Sidebar />
        </Col>
        <Col md={10} className="p-0">
          <div className="main-content col-xl-10 m-auto">
            {children}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Layout;
