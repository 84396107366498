// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';

import Home from './components/home/home.js';
import Principal from './components/principal/principal.js';
import About from './components/about/about.js';
import Furniture from './components/furniture/furniture.js';
import PreOrder from './components/preorder/preorder.js';
import Contact from './components/contact/contact.js';
import Series from './components/series/series.js';
import Terms from './components/terms/terms.js';

import './App.css'; // Importa tus estilos globales

const AnimatedRoutes = () => {
  const location = useLocation(); // Utilizamos useLocation para obtener la ubicación actual

  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/principal" element={<Principal />} />
      <Route path="/about" element={<About />} />
      <Route path="/furniture" element={<Furniture />} />
      <Route path="/preorder" element={<PreOrder />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/series" element={<Series />} />
      <Route path="/terms" element={<Terms />} />
    </Routes>
  );
};

const App = () => {
  return (
    <Router>
      <AnimatedRoutes />
    </Router>
  );
};

export default App;
