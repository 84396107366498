// PreOrder.js
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap'; // Importa las clases de Bootstrap
import Layout from '../layouts/layout.js';
import Footer from '../footer/footer.js'; // Asegúrate de que la ruta sea correcta

const PreOrder = () => {
  return (
    <Layout>
      <Container className="min-vh-100 d-flex flex-column justify-content-between">
        <Row className="d-flex justify-content-center align-items-center text-center flex-grow-1">
          <Col md={8} className="text-left">
            <h2>Pre Orden</h2>
            <p>Todas nuestras piezas cuando quedan fuera de stock se realizan bajo el método de Pre Orden.</p>
            <p>Te invitamos a contactarnos para comprender la pieza que deseas realizar utilizando estos parámetros:</p>
            <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
              <li>- Señalar si la pieza es de la serie de Joyería o Furniture</li>
              <li>- Previo depósito del 50% la pieza se comienza a fabricar</li>
              <li>- Al ser bajo pre orden, esta tiene un tiempo de construcción que puede variar dependiendo de la complejidad de la pieza. (3 a 4 semanas aprox)</li>
              <li>- Al ser un proceso artesanal, las piezas pueden variar levemente en su color y forma final</li>
            </ul>
          </Col>
        </Row>
        <Footer />
      </Container>
    </Layout>
  );
};

export default PreOrder;
