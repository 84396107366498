// Contact.js
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Layout from '../layouts/layout.js';
import Footer from '../footer/footer.js'; // Asegúrate de que la ruta sea correcta

const Contact = () => {
  return (
    <Layout>
      <Container className="min-vh-100 d-flex flex-column justify-content-between">
        <Row className="d-flex justify-content-center align-items-center flex-grow-1 text-center">
          <Col md={6} className="text-center">
            <h2>Comunícate con nosotros:</h2>
            <p><strong>Consultas generales</strong></p>
            <p>contacto@pieceofarte.com</p>
            <p><strong>Redes Sociales:</strong></p>
            <p>@apieceofarte</p>
          </Col>
        </Row>
        <Footer />
      </Container>
    </Layout>
  );
};

export default Contact;
