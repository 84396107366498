import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './sidebar.css'; // Importa los estilos de la barra lateral si los tienes

const Sidebar = () => {
  const location = useLocation(); // Hook para obtener la ruta actual
  const [activeLink, setActiveLink] = useState(location.pathname); // Guarda el enlace activo

  const handleSetActive = (path) => {
    setActiveLink(path);
  };

  return (
    <div className="sidebar align-content-center px-4">
      <ul className="sidebar-list">
        <li>
          <Link
            to="/principal"
            onClick={() => handleSetActive('/principal')}
            className={activeLink === '/principal' ? 'active' : ''}
          >
            MENU
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            onClick={() => handleSetActive('/about')}
            className={activeLink === '/about' ? 'active' : ''}
          >
            About
          </Link>
        </li>
        <li>
          <Link
            to="/series"
            onClick={() => handleSetActive('/series')}
            className={activeLink === '/series' ? 'active' : ''}
          >
            Series
          </Link>
        </li>
        <li>
          <Link
            to="/furniture"
            onClick={() => handleSetActive('/furniture')}
            className={activeLink === '/furniture' ? 'active' : ''}
          >
            Furniture
          </Link>
        </li>
        <li>
          <Link
            to="/preorder"
            onClick={() => handleSetActive('/preorder')}
            className={activeLink === '/preorder' ? 'active' : ''}
          >
            Pre-order
          </Link>
        </li>
        <li>
          <Link
            to="/terms"
            onClick={() => handleSetActive('/terms')}
            className={activeLink === '/terms' ? 'active' : ''}
          >
            Terms & Care
          </Link>
        </li>
        <li>
          <Link
            to="/contact"
            onClick={() => handleSetActive('/contact')}
            className={activeLink === '/contact' ? 'active' : ''}
          >
            Contact
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
