import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './home.css';
import logo from './img/logo.png';

const Home = () => {
  const [slideUp, setSlideUp] = useState(false);
  const [hidden, setHidden] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setSlideUp(true);
  }

  useEffect(() => {
    if (slideUp) {
      const timer = setTimeout(() => {
        setHidden(true);
        navigate('/principal'); // Navegar a otra página después de la animación
      }, 1000); // La duración debe coincidir con la duración de la animación

      return () => clearTimeout(timer);
    }
  }, [slideUp, navigate]);

  return (
    !hidden && (
<div className={`home-container center ${slideUp ? 'slide-up' : ''}`}>
  <div className='container-xxl d-flex row justify-content-lg-between justify-content-sm-center align-items-center'>
    <img src={logo} className='logo col-sm-2' alt='Logo'/>
    <p className='center-now fs-6 text-white col-lg-6 col-sm-6 text-sm-center text-lg-end m-0'>
      COMING SOON
    </p>
  </div>
</div>
    )
  );
}

export default Home;
