// Furniture.js
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap'; // Importa las clases de Bootstrap
import Layout from '../layouts/layout.js';
import Footer from '../footer/footer.js'; // Asegúrate de que la ruta sea correcta

const Furniture = () => {
  // Contenido dinámico
  const content = {
    title: "Piece of arte",
    description: "Furniture es nuestra línea de objetos decorativos de uso personal o espacios",
    image1: './img/image1.png',  // Asegúrate de que la ruta a la imagen sea correcta
    image2: './img/image2.png'   // Asegúrate de que la ruta a la imagen sea correcta
  };

  return (
    <Layout>
      <Container className="min-vh-100 d-flex flex-column justify-content-between">
        <Row className="d-flex justify-content-center align-items-center text-center flex-grow-1">
          <Col md={8}> {/* Ajusta la columna según sea necesario */}
            <p>{content.description}</p>
            <img src={content.image1} alt="Bola" className="img-fluid mb-4" />
            <img src={content.image2} alt="Bola" className="img-fluid" />
          </Col>
        </Row>
        <Footer />
      </Container>
    </Layout>
  );
};

export default Furniture;
